import { Component, Inject, Injector } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent, Channel, ChannelService, ChannelTypeEnum } from 'lib-trend-core';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'channel-edit-component',
  templateUrl: './channel-edit.component.html',
  styleUrls: ['./channel-edit.component.scss']
})
export class ChannelEditComponent extends AbstractComponent {

  channel: Channel;
  channels: Array<Channel> = new Array<Channel>();

  cloudAPI: ChannelTypeEnum = ChannelTypeEnum.CLOUD_API;
  evolutionAPI: ChannelTypeEnum = ChannelTypeEnum.EVOLUTION_API;
  evolutionGOAPI: ChannelTypeEnum = ChannelTypeEnum.EVOLUTION_GO_API;

  constructor(
    injector: Injector,
    public channelService: ChannelService,
    public dialogRef: MatDialogRef<ChannelEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Channel,
  ) {
    super(injector);
    this.channel = { metadata: {} } as Channel;
    this.createForm();
  }

  ngOnInit() {
    this.isNew = true;
    if (this.data?._id) {
      this.isNew = false;
      this.getChannelById(this.data?._id);
    }
    this.getAllChannelsByCompany();
  }

  private createForm(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(this.channel.name, [Validators.required]),
      type: new FormControl(this.channel.type, [Validators.required]),
      integration: new FormControl(true),
      metadata: new FormGroup({
        token: new FormControl(this.channel.metadata['token']),
        phoneNumberID: new FormControl(this.channel.metadata['phoneNumberID']),
        whatsAppBusinessAccountID: new FormControl(this.channel.metadata['whatsAppBusinessAccountID']),
      }),
    });
    this.formGroup.valueChanges.subscribe(value => {
      Object.assign(this.channel, value);
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  getAllChannelsByCompany() {
    this.channelService.getList()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (channels: Array<Channel>) => {
          this.channels = channels;
        },
        error: (err) => this.alertService.error(err.error.message)
      });
  }

  getChannelById(idChannel: string) {
    this.channelService.getById(idChannel)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (channel: Channel) => {
          this.channel = channel;
          this.formGroup.patchValue(this.channel);
          this.formGroup.get('integration').setValue(false);
        },
        error: (err) => this.alertService.error(err.error.message)
      });
  }

  save() {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup);
      return;
    }

    if (this.isNew) {
      this.channelService.create(this.channel).subscribe({
        next: () => {
          this.alertService.success('Canal criado com sucesso!');
          this.getAllChannelsByCompany();
        },
        error: (err) => this.alertService.error(err.error.message),
      });
    } else {
      this.channelService.update(this.channel._id, this.channel).subscribe({
        next: () => {
          this.alertService.success('Canal atualizado com sucesso!');
          this.getAllChannelsByCompany();
        },
        error: (err) => this.alertService.error(err.error.message),
      });
    }
  }

  checkIfExceded(channelTypeEnum: ChannelTypeEnum) {
    const currentCloudAPI = this.channels.filter((c) => c.type === channelTypeEnum.toString()).length;
    const currentBusiness = this.channels.filter((c) => c.type === channelTypeEnum.toString()).length;
    const currentBusinessPRO = this.channels.filter((c) => c.type === channelTypeEnum.toString()).length;

    const companySession = super.getCompanyCurrentUser();
    const maxCloudAPI = companySession.enabledChannel.maxCloudAPI;
    const maxBusiness = companySession.enabledChannel.maxBusiness;
    const maxBusinessPRO = companySession.enabledChannel.maxBusinessPRO;

    if (this.isNew) {
      if (channelTypeEnum === ChannelTypeEnum.CLOUD_API) {
        return (currentCloudAPI >= maxCloudAPI);
      } else if (channelTypeEnum === ChannelTypeEnum.EVOLUTION_API) {
        return (currentBusiness >= maxBusiness);
      } else if (channelTypeEnum === ChannelTypeEnum.EVOLUTION_GO_API) {
        return (currentBusinessPRO >= maxBusinessPRO);
      }
    }
    return false;
  }

  valueChangeType() {
    const type = this.formGroup.get('type').value;
    if (type === ChannelTypeEnum.CLOUD_API) {
      this.formGroup.get('metadata').get('token').setValidators([Validators.required]);
      this.formGroup.get('metadata').get('phoneNumberID').setValidators([Validators.required]);
      this.formGroup.get('metadata').get('whatsAppBusinessAccountID').setValidators([Validators.required]);
    } else {
      this.formGroup.get('metadata').get('token').clearValidators();
      this.formGroup.get('metadata').get('phoneNumberID').clearValidators();
      this.formGroup.get('metadata').get('whatsAppBusinessAccountID').clearValidators();
    }
    this.formGroup.get('metadata').get('token').updateValueAndValidity();
    this.formGroup.get('metadata').get('phoneNumberID').updateValueAndValidity();
    this.formGroup.get('metadata').get('whatsAppBusinessAccountID').updateValueAndValidity();
  }

  showSaveButton() {
    return this.isAdmin() && (!this.formGroup.get('integration').value || !this.isCloudAPI());
  }

  getName() {
    return this.formGroup.get('name').value;
  }

  selectType(type: string) {
    this.formGroup.get('type').setValue(ChannelTypeEnum[type]);
    if (this.checkIfExceded(ChannelTypeEnum[type])) {
      this.alertService.warning('Limite de canais excedido!');
    }
  }

  isCloudAPI() {
    return this.formGroup.get('type').value === ChannelTypeEnum.CLOUD_API;
  }

  isIntegration() {
    return this.formGroup.get('integration').value;
  }

  showIntegrationOption() {
    return this.isNew && this.isCloudAPI();
  }
}
