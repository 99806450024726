import { AfterViewInit, Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractComponent, Channel, ChannelEmbedded, ChannelService } from 'lib-trend-core';
import { environment } from '../../../environments/environment';

declare var FB: any;

@Component({
  selector: 'app-whatsapp-signup',
  templateUrl: './whatsapp-signup.component.html',
  styleUrls: ['./whatsapp-signup.component.scss']
})
export class WhatsappSignupComponent extends AbstractComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() label: string = 'Start WhatsApp Signup';
  @Input() nameChannel: string;
  @Input() disabledButton: boolean;
  @Output() onSave: EventEmitter<Channel> = new EventEmitter<Channel>();

  labelButton = 'Conectar com WhatsApp Embedded';

  constructor(
    injector: Injector,
    public channelService: ChannelService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.loading = false;
    this.labelButton = 'Conectar com WhatsApp Embedded';
    window.addEventListener('message', this.handleMessageEvent);
  }

  override ngOnDestroy(): void {
    // Clean up event listener to avoid memory leaks
    window.removeEventListener('message', this.handleMessageEvent);
  }

  ngAfterViewInit() {
    this.loadWhatsappSignup();
  }

  loadWhatsappSignup() {
    // Verifica se o objeto FB está carregado
    if (typeof FB !== 'undefined' && FB) {
      FB.XFBML.parse(); // Reprocessa os elementos do DOM para incluir o botão
    }
  }

  launchWhatsAppSignup() {
    this.loading = true;
    this.labelButton = 'Configurando WhatsApp no Business Manager do Meta... ';
    FB.login(this.fbLoginCallback, {
      config_id: environment.cloudAPI.configId, // your configuration ID goes here
      response_type: 'code',
      override_default_response_type: true,
      extras: {
        setup: {},
        featureType: '',
        sessionInfoVersion: '3',
      }
    });
  }

  fbLoginCallback = (response) => {
    if (response.authResponse) {
      // remove after testing
    } else {
      // your code goes here
    }
  }

  private handleMessageEvent = (event: MessageEvent) => {
    console.debug('message event: ', event.data); // Debugging log
    // Ensure the event comes from the correct origin
    if (event.origin !== 'https://www.facebook.com' && event.origin !== 'https://web.facebook.com') {
      return;
    }
    try {
      const data = JSON.parse(event.data);
      if (data.type === 'WA_EMBEDDED_SIGNUP' && data.event === "FINISH") {
        this.labelButton = 'Finalizando a configuração do WhatsApp Business Oficial... ';
        const { phone_number_id, waba_id } = data.data;
        const channelEmbedded = <ChannelEmbedded>{
          name: this.nameChannel || 'WhatsApp Channel Integration',
          phoneNumberID: String(phone_number_id),
          whatsAppBusinessAccountID: String(waba_id)
        };
        this.channelService.createFromEmbedded(channelEmbedded).subscribe({
          next: (channel: Channel) => {
            this.loading = false;
            this.labelButton = 'Conectar com WhatsApp Embedded';

            this.alertService.success('Canal criado com sucesso!');
            this.onSave.emit(channel);
          },
          error: (err) => this.alertService.error(err.error.message)
        });
      }
    } catch {
      console.error('message event (non-JSON): ', event.data); // Debugging log
      // Your custom code here
    }
  };
}