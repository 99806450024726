<div class="flex flex-1 flex-col px-5">

  <div class="mb-2 flex justify-between">
    <div class="flex flex-col">
      <h1 class="font-black text-[20px] text-black">Canal - {{channel?.name}}</h1>
      <h6 class="text-zinc-400 font-light text-sm mt-2">Visualização do Canal - {{getTranslatedTypeChannel(channel?.type)}}</h6>
    </div>
  </div>
  <hr />

  <channel-config-component *ngIf="!!channel" [channel]="channel"></channel-config-component>
</div>