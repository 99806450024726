import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-trend-core';
import { WhatsappSignupComponent } from './whatsapp-signup.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    WhatsappSignupComponent
  ],
  exports: [
    WhatsappSignupComponent
  ]
})
export class WhatsappSignupModule { }
