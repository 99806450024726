import { AfterContentInit, Component, Injector, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LegendPosition } from '@swimlane/ngx-charts';
import { AbstractComponent, Channel, ChannelService, ChannelTypeEnum, ConfirmationComponent } from 'lib-trend-core';
import { BehaviorSubject, concatMap, map, mergeMap, of, takeUntil, tap, timer } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ChannelEditComponent } from '../channel-edit/channel-edit.component';

@Component({
  selector: 'channel-config-component',
  templateUrl: './channel-config.component.html',
  styleUrls: ['./channel-config.component.scss']
})
export class ChannelConfigComponent extends AbstractComponent implements OnInit, AfterContentInit {

  @Input('channel') channel: Channel;

  private loadingSpinnerSubject = new BehaviorSubject<boolean>(false);
  loadingSpinner$ = this.loadingSpinnerSubject.asObservable();

  INSTANCE_CODE_DATA: string;
  connected: boolean = false;
  countContacts: number = 0;
  countChats: number = 0;
  countMessages: number = 0;

  selectedTabToDestroy!: string;

  dataChartOfChannel: Array<{ name: string, series: Array<{ name: string, value: number }> }>;
  totalContacts: string;
  totalMessages: string;
  totalAttendances: string;

  legendPosition: LegendPosition = LegendPosition.Right;
  customColors: Array<{ name: string, value: string }> = [
    { name: 'Total de mensagens enviadas', value: '#4213F6' },
    { name: 'Total de mensagens recebidas', value: '#8F8CFF' },
  ]

  constructor(
    injector: Injector,
    public channelService: ChannelService,
    private dialog: MatDialog,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getChannelWithMetrics();
  }

  ngAfterContentInit(): void {
    this.init();
  }

  // Method for fetch base64 and with interval of 20 seconds
  private init(): void {
    if (!!this.channel && this.channel.type !== ChannelTypeEnum.CLOUD_API) {
      this.loadingSpinnerSubject.next(true);
      timer(0, 30000).pipe(
        mergeMap(() => this.channelService.syncInstanceByChannel(this.channel._id)),
        concatMap((channel) => {
          this.channel = channel;
          if (channel.type === ChannelTypeEnum.EVOLUTION_API) {
            this.countContacts = this.channel.metadata['_count']['Contact'];
            this.countChats = this.channel.metadata['_count']['Chat'];
            this.countMessages = this.channel.metadata['_count']['Message'];
          }
          this.connected = this.checkIfChannelIsConnected(channel.type);
          if (this.connected) {
            return of(null);
          } else {
            return this.channelService.getInstanceConnect(this.channel._id)
              .pipe(
                map((value) => {
                  this.INSTANCE_CODE_DATA = (channel.type === ChannelTypeEnum.EVOLUTION_API ? value['code'] : value['data']['Code']);
                }),
                takeUntil(this.destroy$),
              );
          }
        }),
        tap(() => this.loadingSpinnerSubject.next(false)),
        takeUntil(this.destroy$),
      ).subscribe({
        complete: () => this.loadingSpinnerSubject.next(false)
      });
    }
  }

  private checkIfChannelIsConnected(type: ChannelTypeEnum): boolean {
    if (type === ChannelTypeEnum.EVOLUTION_API) {
      return this.channel.metadata['connectionStatus'] === 'open';
    } else {
      return this.channel.metadata['connected'];
    }
  }

  close(): void {
  }

  sync(): void {
    this.loadingSpinnerSubject.next(true);
    this.channelService.syncInstanceByChannel(this.channel._id).subscribe({
      next: (channel: Channel) => this.channel = channel,
      error: (err) => this.alertService.error(err.error.message),
      complete: (() => this.loadingSpinnerSubject.next(false))
    });
  }

  syncContacts(): void {
    this.loadingSpinnerSubject.next(true);
    this.channelService.syncContactsByChannel(this.channel._id).subscribe({
      next: (channel: Channel) => {
        this.channel = channel;
        this.alertService.success('Contatos sincronizados com sucesso!');
      },
      error: (err) => this.alertService.error(err.error.message),
      complete: (() => this.loadingSpinnerSubject.next(false))
    });
  }


  restart(): void {
    this.loadingSpinnerSubject.next(true);
    this.channelService.restartInstanceByChannel(this.channel._id).subscribe({
      next: (channel: Channel) => this.channel = channel,
      error: (err) => this.alertService.error(err.error.message),
      complete: (() => this.loadingSpinnerSubject.next(false))
    });
  }

  disconnect(): void {
    this.loadingSpinnerSubject.next(true);
    this.channelService.disconnectInstanceByChannel(this.channel._id).subscribe({
      next: (channel: Channel) => this.channel = channel,
      error: (err) => this.alertService.error(err.error.message),
      complete: (() => this.loadingSpinnerSubject.next(false))
    });
  }

  changeTabToDestroy(tab: string): void {
    this.selectedTabToDestroy = tab;
  }

  private getChannelWithMetrics(): void {
    let phoneNumber: string;

    if (!!this.channel.metadata) {
      if (!!this.channel.metadata?.['jid'] && this.channel.type === ChannelTypeEnum.EVOLUTION_GO_API) {
        phoneNumber = this.channel.metadata?.['jid'].replace(/@s\.whatsapp\.net$/, '');
      } else if (!!this.channel.metadata?.['ownerJid'] && this.channel.type === ChannelTypeEnum.EVOLUTION_API) {
        phoneNumber = this.channel.metadata?.['ownerJid'].replace(/@s\.whatsapp\.net$/, '');
      } else if (this.channel.type === ChannelTypeEnum.CLOUD_API) {
        phoneNumber = this.channel.metadata?.['phoneNumberInfo']['display_phone_number'].replace(/[^0-9.]/g, '');
      }
    }

    this.channelService.getByIdAndMetrics(this.channel._id, phoneNumber).subscribe({
      next: (value) => {
        this.dataChartOfChannel = [
          {
            name: 'Total de mensagens enviadas',
            series: [
              {
                name: 'Total de mensagens enviadas',
                value: value.find((value) => value).sentMessages ?? 0,
              },
            ],
          },
          {
            name: 'Total de mensagens recebidas',
            series: [
              {
                name: 'Total de mensagens recebidas',
                value: value.find((value) => value).receivedMessages ?? 0,
              },
            ],
          },
        ];

        this.totalContacts = value.find((value) => value).totalContacts.toString() ?? '0';
        this.totalMessages = value.find((value) => value).totalMessages.toString() ?? '0';
        this.totalAttendances = value.find((value) => value).totalAttendance.toString() ?? '0';
      },
      error: (err) => { }
    });
  }

  get env() {
    return environment.env;
  }

  edit(item: Channel): void {
    const dialogRef = this.dialog.open(ChannelEditComponent, {
      width: '600px',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  delete(channel: Channel) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.channelService.delete(channel._id).subscribe({
          next: () => {
            this.alertService.success();
          },
          error: err => this.alertService.error(err.error.message)
        })
      }
    });
  }
}
