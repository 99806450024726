<div class="w-full h-full">
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="#D1FADF" />
    <rect width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
    <path
      d="M29.13 17.84C28.77 17.33 28.17 17 27.5 17L16.5 17.01C15.4 17.01 14.5 17.9 14.5 19V29C14.5 30.1 15.4 30.99 16.5 30.99L27.5 31C28.17 31 28.77 30.67 29.13 30.16L33.5 24L29.13 17.84ZM27.5 29H16.5V19H27.5L31.05 24L27.5 29Z"
      fill="#039855" />
  </svg>
  <h1 class="font-black text-[20px] text-black mt-2.5">{{isNew ? 'Adicionar' : 'Alterar'}} canal</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Preencha os dados e crie um novo etiqueta</h6>

  <form [formGroup]="formGroup">
    <div class="flex flex-col">
      <label class="font-semibold text-black mt-2">Nome *</label>
      <input maxlength="100" formControlName="name" class="input-field" placeholder="Digite o nome do seu canal...">
      <field-error-component [field]="'name'" [label]="'Nome'" [formGroup]="formGroup"></field-error-component>
    </div>
    @if (formGroup.get('type')?.value === 'CLOUD_API') {
    @if (formGroup.get('integration')?.value === false) {
    <div class="flex flex-col mt-[15px]" formGroupName="metadata">
      <div class="flex flex-col">
        <label class="font-semibold text-black">Token *</label>
        <input type="password" formControlName="token" class="input-field" placeholder="Digite o seu token...">
        <field-error-component [field]="'metadata.token'" [label]="'Token'"
          [formGroup]="formGroup"></field-error-component>
      </div>
      <div class="flex w-full mt-[15px] gap-2">
        <div class="flex w-[49%] flex-col">
          <label class="font-semibold text-black">Phone Number ID *</label>
          <input maxlength="100" formControlName="phoneNumberID" class="input-field"
            placeholder="Digite o Phone Number ID...">
          <field-error-component [field]="'metadata.phoneNumberID'" [label]="'Phone Number ID'"
            [formGroup]="formGroup"></field-error-component>
        </div>
        <div class="flex w-[49%] flex-col">
          <label class="font-semibold text-black">WhatsApp Business Account ID *</label>
          <input maxlength="100" formControlName="whatsAppBusinessAccountID" class="input-field"
            placeholder="Digite o WhatsApp Business ID...">
          <field-error-component [field]="'metadata.whatsAppBusinessAccountID'" [label]="'WhatsApp Business Account ID'"
            [formGroup]="formGroup"></field-error-component>
        </div>
      </div>
    </div>
    } @else {
    <br />
    <app-whatsapp-signup [disabledButton]="!getName()" [nameChannel]="getName()"
      (onSave)="goBack()"></app-whatsapp-signup>
    }
    }
  </form>
</div>
<div class="flex justify-between items-center w-full gap-2 mt-[15px]">
  <button
    class="h-[45px] flex flex-1 justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100"
    (click)="close()">Cancelar</button>
  <button
    class="h-[45px] flex flex-1 justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal  hover:bg-[#1340f6]"
    (click)="save()" *ngIf="showSaveButton()">{{isNew? 'Adicionar' : 'Alterar'}}</button>
</div>